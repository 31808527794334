import React from 'react';

const SpecialOffersF4F = () => {
  return (
    <>
      <iframe
        title="FlirtForFreeVideo1"
        src="https://secure.vs3.com/_special/banners/LiveWebCams.php?cta=gtr&style=700x420-lva-theme001&mp_code=d8gn5&service=guys&use_promo=0&bgcolor=000000&txtcolor=FFFFFF&linkcolor=00A7E1&num_models=50&sitekey=whitelabel&whitelabel_domain=nakedswordlive.com&target=_blank&btncolor=00A7E1&btntxtcolor=FFFFFF&accentcolor=112D5D"
        scrolling="no"
        width="700"
        height="420"
        frameBorder="0"
        style={{ margin: '0 0 1em' }}
      ></iframe>

      <iframe
        title="FlirtForFreeBanner1"
        src="https://secure.vs3.com/_special/banners/LiveWebCams.php?cta=gtr&style=700x700-theme001-jscroll-l2r-0001&mp_code=d8gn5&service=guys&use_promo=0&bgcolor=8B95A3&txtcolor=3D547B&linkcolor=ffffff&num_models=50&sitekey=whitelabel&whitelabel_domain=nakedswordlive.com&target=_blank&btncolor=3D547B&btntxtcolor=ffffff&accentcolor=3D547B"
        scrolling="no"
        width="700"
        height="175"
      ></iframe>
      <p style={{ width: '100%', textAlign: 'center' }}>NakedSwordLive.com the hottest guys online.</p>
    </>
  );
};

export default SpecialOffersF4F;
