import React, { useEffect, useState, useRef } from 'react';
import { getScenes } from '../../../services/scenes-service/scenes.service';
import { navigate } from '@reach/router';

import MostPopularGrid from './MostPopular/MostPopularGrid.js';
import NewTodayCarousel from './NewToday/NewTodayCarousel.js';

import './MainDashboardView.scss';
import { stringToSlug } from '../../../services/util-service/util.service';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc';
import { ROUTES } from '../../../services/navigation/navigation.routes';
import {
  RenderConditionalComponent,
  PROPERTY_ID,
} from '../../../components/RenderConditionalComponent/RenderConditionalComponent';
import StudioResults from './StudioResults';
import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import useInViewport from '../../../hook/useInViewport';
import TopTen from '../TopTen/TopTen';
import FeaturedRelease from './FeaturedRelease/FeaturedRelease';
import { useWLconfigContext } from '../../../context/WLConfigContext';
import { useAuthContext } from '../../../context/AuthContext';

const MainDashboardView = () => {
  const [state, setState] = useState({
    config: {},
    videos: [],
    viewAllLink: '',
    loading: true,
    error: false,
  });
  const { config, videos = [], viewAllLink, loading, error } = state;
  const { userLoggedIn } = useAuthContext();
  const { wl_config: { homepage_spotlight } = {} } = useWLconfigContext();

  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);

  useEffect(() => {
    if (!isVisible) return;

    if (homepage_spotlight && Object.keys(homepage_spotlight).length !== 0) {
      let filter = Object.entries(homepage_spotlight)[0];
      if (filter?.length) {
        const { viewAllLink, ...filterObject } = processFilters(filter);
        const [, homepage_spotlight] = filter;

        getScenes(filterObject)
          .then((resp = {}) => {
            const { scenes = [] } = resp.data?.data;
            setState((prevState) => ({
              ...prevState,
              videos: scenes,
              config: homepage_spotlight || {},
              viewAllLink,
            }));
          })
          .catch((err) => {
            console.log(err);
            setState((prevState) => ({ ...prevState, error: true }));
          })
          .finally(() => setState((prevState) => ({ ...prevState, loading: false })));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
    return () => {
      setState({});
    };
  }, [homepage_spotlight, isVisible]);

  const processFilters = (filter) => {
    const [type, config] = filter;
    const { id, name } = config;
    const data = { sort_by: 'newest' };
    switch (type) {
      case 'studio':
        const studioLink = ROUTES.studiosInfo.replace(':studioId', id).replace(':studioName', name);
        Object.assign(data, { viewAllLink: studioLink, studios_id: id });
        break;
      case 'star':
        const starLink = ROUTES.starInfo.replace(':starId', id).replace(':starName', name);
        Object.assign(data, { viewAllLink: starLink, stars_id: id });
        break;
      case 'theme':
        const themeLink = ROUTES.themeInfo.replace(':name', name);
        Object.assign(data, { viewAllLink: themeLink, tags_id: id });
        break;
      default:
        break;
    }
    return data;
  };

  const renderVideos = () => {
    const link = stringToSlug(viewAllLink);

    return (
      <MoviesScenesGrid
        title={videos.length !== 0 ? 'Spotlight on ' + config?.name || '' : ''}
        viewAllLink={{ text: 'View All', url: link }}
        data={videos}
        loading={loading}
        error={error}
        hideNavigation
        hidePoster
        refProp={containerRef}
      />
    );
  };

  return (
    <div className="MainDashboardView">
      <MostPopularGrid hidePoster />
      <NewTodayCarousel hidePoster />

      <StudioResults hidePoster />
      <RenderConditionalComponent ids={[PROPERTY_ID.nakedSword, PROPERTY_ID.guerillaPorn]}>
        <FeaturedRelease />
      </RenderConditionalComponent>

      <TopTen excludeBanners={true} />
      {renderVideos()}
      <BannerSetViewFunc bannerName="tour" />
      {!userLoggedIn && <BannerSetViewFunc bannerName="nonMemberPromoAffiliate" />}
      {!userLoggedIn && <BannerSetViewFunc bannerName="whatMembershipBringsYou" onClick={() => navigate('/join')} />}
    </div>
  );
};

MainDashboardView.displayName = 'MainDashboardView';

export default MainDashboardView;
