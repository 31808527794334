import React, { useRef } from 'react';

import { getOriginalsScenesPageRoute } from '../../../services/navigation/navigation.service.routes';
import useInViewport from '../../../hook/useInViewport';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';
import { isWeb } from '../../../services/util-service/util.service';

import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid';
import BaseSectionHeader from '../../../components/BaseSectionHeader/BaseSectionHeader';

const StudioResults = ({ hidePoster }) => {
  const containerRef = useRef(null);
  const isVisible = useInViewport(containerRef);

  const { videos, loading, error } = useMoviesScenesData({
    hookParams: { studios_id: 22309 },
    isVisible,
  });

  return (
    <>
      <div style={{ padding: isWeb() ? '0 1em' : '0 0.5em' }}>
        <BaseSectionHeader
          secondaryLink={getOriginalsScenesPageRoute(1, 'Newest')}
          secondaryText='View All'>
          <span style={{ width: '6.5em', marginRight: '.3em', pointerEvents: 'none' }}>{logoText}</span>
          <span>Originals</span>
        </BaseSectionHeader>
      </div>
      <MoviesScenesGrid
        data={videos}
        loading={loading}
        error={error}
        hideNavigation
        hidePoster={hidePoster}
        refProp={containerRef}
      />
    </>
  );
};

StudioResults.displayName = 'StudioResults';

export default StudioResults;

const logoText = <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 637.082 70">
  <g id="Footer_Logo" data-name="Footer Logo" transform="translate(-15.6 -8.5)">
    <g id="Grupo_50057" data-name="Grupo 50057" transform="translate(15.6 8.5)">
      <path id="Trazado_1" data-name="Trazado 1" d="M57.056,77.447,30.8,34.756h-.237l.332,42.691H15.6V12.2h18L59.763,54.748H60L59.668,12.2H74.958V77.447Z" transform="translate(-15.6 -10.443)" fill="#142d59" />
      <path id="Trazado_2" data-name="Trazado 2" d="M198.383,77.447,193.3,64.625H168.039l-4.8,12.821H146.1L173.452,12.2h15.338l27.067,65.247ZM180.86,29.628,172.6,51.994H188.98Z" transform="translate(-84.13 -10.443)" fill="#142d59" />
      <path id="Trazado_3" data-name="Trazado 3" d="M337.989,77.447,313.771,47.388h-.19V77.447H298.1V12.2h15.481V38.745h.285L337.182,12.2h19.707L328.776,42.212l29.774,35.235Z" transform="translate(-163.95 -10.443)" fill="#142d59" />
      <path id="Trazado_4" data-name="Trazado 4" d="M429.5,77.447V12.2h43.83V25.544H444.7V37.8h27.067V50.427H444.7V63.961h30.3V77.447Z" transform="translate(-232.952 -10.443)" fill="#142d59" />
      <path id="Trazado_5" data-name="Trazado 5" d="M559.913,77.447H535.6V12.2h23.6c18.52,0,37.325,7.74,37.325,32.433-.047,22.936-18.662,32.813-36.612,32.813m-1.092-51.808h-7.835V63.771h7.455c11.254,0,21.654-4.606,21.654-19.185,0-14.626-10.4-18.947-21.274-18.947" transform="translate(-288.669 -10.443)" fill="#142d59" />
      <path id="Trazado_6" data-name="Trazado 6" d="M693.031,106.231c0,5.271-4.226,7.076-9.022,7.076a19.214,19.214,0,0,1-14.1-6.933l-10.3,10.495c5.7,5.983,15.1,9.4,24.408,9.4,12.916,0,24.978-6.743,24.978-21.749a20.684,20.684,0,0,0-.142-2.422l-16.288,1.615a6.581,6.581,0,0,1,.475,2.517" transform="translate(-353.785 -57.652)" fill="#142d59" />
      <path id="Trazado_7" data-name="Trazado 7" d="M692.228,35.81c-6.173-1.947-10.02-3.324-10.02-7.645,0-5.176,5.081-6.553,9.118-6.553a15.871,15.871,0,0,1,11.587,5.6l10.02-10.59c-5.7-5.271-14.009-8.025-21.369-8.025-12.157,0-25.263,5.983-25.263,20.467,0,11.777,8.405,16.051,16.668,18.71,2.517.807,4.749,1.52,6.6,2.232l21.417-1.852c-3.324-7.313-11.824-10.115-18.757-12.347" transform="translate(-357.304 -8.553)" fill="#142d59" />
      <path id="Trazado_8" data-name="Trazado 8" d="M782.853,12.2H765.9L776.347,49l14.958-1.33Z" transform="translate(-409.607 -10.443)" fill="#142d59" />
      <path id="Trazado_9" data-name="Trazado 9" d="M806.466,98.086h-.285l-.807-3.467L790.7,96.092l6.743,23.838h15.671l7.835-26.83-13.486,1.33Z" transform="translate(-422.63 -52.926)" fill="#142d59" />
      <path id="Trazado_10" data-name="Trazado 10" d="M845.885,33.284h.285l3.562,12.2,13.3-1.14L854.385,12.2h-16.43L828.6,47.34l13.534-1.187Z" transform="translate(-442.533 -10.443)" fill="#142d59" />
      <path id="Trazado_11" data-name="Trazado 11" d="M938.175,12.2h-16.43L914.1,43.826l15.433-1.33Z" transform="translate(-487.432 -10.443)" fill="#142d59" />
      <path id="Trazado_12" data-name="Trazado 12" d="M891.033,93.36h-.285L888.8,86.047l-13.2,1.282,8.168,27.875h15.576l8.88-31.1-15.338,1.52Z" transform="translate(-467.214 -48.2)" fill="#142d59" />
      <path id="Trazado_13" data-name="Trazado 13" d="M990.725,22.7a17.948,17.948,0,0,1,17.665,12.916l17-1.472C1021.876,18.187,1008.248,8.5,990.725,8.5,971.16,8.5,956.44,20.657,955.3,40.174L972.4,38.7c1.567-9.4,8.6-16,18.33-16" transform="translate(-509.067 -8.5)" fill="#142d59" />
      <path id="Trazado_14" data-name="Trazado 14" d="M1009.435,73.893c0,11.777-7.74,20.277-18.71,20.277S972.11,85.718,972.11,73.893v-.427L955.3,75.176c.618,19.944,15.528,33.383,35.473,33.383,20.467,0,35.568-14.009,35.568-34.665a39.161,39.161,0,0,0-.428-5.793l-16.81,1.662a24.234,24.234,0,0,1,.333,4.131" transform="translate(-509.067 -39.798)" fill="#142d59" />
      <path id="Trazado_15" data-name="Trazado 15" d="M1136.441,63.438h-7.455V60.067l-15.386,1.52v39.177h15.48V74.835h5.366l14.1,25.88h18.425l-17.1-27.875c7.93-2.422,12.964-8.215,13.486-16.24l-15.956,1.615c-1.472,4.274-6.7,5.224-10.969,5.224" transform="translate(-592.196 -33.759)" fill="#142d59" />
      <path id="Trazado_16" data-name="Trazado 16" d="M1128.986,24.926h8.405c4.7,0,10.494,1.187,10.494,7.265v.522l15.576-1.33c-.285-14.483-12.584-19.185-24.978-19.185H1113.6V35.658l15.386-1.33Z" transform="translate(-592.196 -10.443)" fill="#142d59" />
      <path id="Trazado_17" data-name="Trazado 17" d="M1273.495,61.07c0,14.578-10.4,19.185-21.654,19.185h-7.455V47.679L1229,49.2V93.836h24.313c17.95,0,36.565-9.877,36.565-32.813,0-7.218-1.614-12.964-4.321-17.523l-19.469,1.947c4.416,2.9,7.408,7.788,7.408,15.623" transform="translate(-652.796 -26.88)" fill="#142d59" />
      <path id="Trazado_18" data-name="Trazado 18" d="M1244.386,25.639h7.835a28.555,28.555,0,0,1,11.824,2.279l20.942-1.8C1278.2,15.809,1265.327,12.2,1252.6,12.2H1229V30.957l15.386-1.33V25.639Z" transform="translate(-652.796 -10.443)" fill="#142d59" />
    </g>
  </g>
</svg>
