import React from 'react';
import { Link } from '@reach/router';

import { ROUTES } from '../../../../services/navigation/navigation.routes';
import { isWeb } from '../../../../services/util-service/util.service';
import BannerSetViewFunc from '../../../../components/BannerSetView/BannerSetViewFunc';
import FreeRideOrDieBonusVideo from './FreeRideOrDieBonusVideo';
import FreeRideOrDieBonusBehindScenes from './FreeRideOrDieBonusBehindScenes';
import FreeRideOrDieBonusHadItComing from './FreeRideOrDieBonusHadItComing';
import FreeRideOrDieBonusMeetTheWarden from './FreeRideOrDieBonusMeetTheWarden';

// Here are all of the links:
// https://d34kds0xkdmtq8.cloudfront.net/RSS190_SM.mp4
// https://d34kds0xkdmtq8.cloudfront.net/RSS190_SL.mp4
// https://d34kds0xkdmtq8.cloudfront.net/RSS190_SK.mp4
// https://d34kds0xkdmtq8.cloudfront.net/RSS190_SJ.mp4

export default function FreeMain(props) {
  const { path } = props;

  const renderContent = () => {
    let view = null;

    switch (path) {
      case ROUTES.freerideordiebonusvideo:
        view = <FreeRideOrDieBonusVideo />;
        break;
      case ROUTES.freerideordiebonusbehindscenes:
        view = <FreeRideOrDieBonusBehindScenes />;
        break;
      case ROUTES.freerideordiebonushaditcoming:
        view = <FreeRideOrDieBonusHadItComing />;
        break;
      case ROUTES.freerideordiebonusmeetthewarden:
        view = <FreeRideOrDieBonusMeetTheWarden />;
        break;
      default:
        view = null;
    }

    return view;
  };

  const renderHeader = () => {
    return (
      <img
        src="https://spotlight.nakedcdn.com/nakedsword/img/free/free-header-logo.png"
        alt="Nakedsword Weekends"
        style={{ maxWidth: '50%', margin: '2em auto' }}
      />
    )
  };

  const renderBottomBoxes = () => {
    const data = [{
      url: ROUTES.freerideordiebonusbehindscenes,
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/behindbars-bonus-nav-rideordie.png', alt: 'Behind Bars - Featurette'
    }, {
      url: ROUTES.freerideordiebonusmeetthewarden,
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/meetwarden-bonus-nav-rideordie.png', alt: 'Meet the Warden'
    }, {
      url: ROUTES.freerideordiebonushaditcoming,
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/cellbtango-bonus-nav-rideordie.png', alt: 'Cellblock Tango'
    }, {
      url: ROUTES.freerideordiebonusvideo,
      src: 'https://spotlight.nakedcdn.com/nakedsword/img/free/rideordie/rideonit-bonus-nav-rideordie.png',
      alt: 'Free Ride Or - Ride On'
    }];

    return (
      <>
        {data.map(item => {
          const { url, src, alt } = item;

          return (
            <Link to={`/${url}`} key={url}>
              <img
                src={src}
                alt={alt}
                style={{ maxWidth: '14em' }}
              />
            </Link>
          )
        })}
      </>
    )
  };

  return (
    <div style={{
      maxWidth: isWeb() ? '90%' : '95%',
      margin: 'auto',
      fontFamily: 'var(--font-family-metropolis)',
      textAlign: 'center',
      lineHeight: '1.25'
    }}>
      {renderHeader()}
      <BannerSetViewFunc bannerName="promo" />
      {renderContent()}
      {renderBottomBoxes()}
      <BannerSetViewFunc bannerName="tour" />
    </div>
  );
};
