import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import './MemberDealsPage.scss';

const FILE_URL = "https://storage.googleapis.com/ns-benefit-banner-data/member_banners.csv";
const METADATA_URL = "https://storage.googleapis.com/storage/v1/b/ns-benefit-banner-data/o/member_banners.csv?alt=json"; // For metadata like ETag/Last-Modified
const CACHE_KEY = "memberBenefitsData";
const CACHE_VERSION_KEY = "memberBenefitsVersion";

const DealsImport = ({ numberOfDisplayedOffers }) => {
  const [data, setData] = useState([]);
  const [shuffledData, setShuffledData] = useState([]);
  const currentDate = new Date();

  // ✅ Fetch Metadata (to get ETag or Last-Modified)
  const fetchMetadata = async () => {
    //console.log("🛠 Fetching metadata...");
    try {
      const response = await fetch(METADATA_URL);
      if (!response.ok) throw new Error(`Metadata fetch error! Status: ${response.status}`);

      const metadata = await response.json();
      //console.log("✅ Metadata Fetched:", metadata);

      return metadata?.etag || null; // We use ETag or Last-Modified for versioning
    } catch (error) {
      //console.error("❌ Error fetching metadata:", error);
      return null;
    }
  };

  // ✅ Fetch CSV Data (Only If Necessary)
  const fetchCSVData = async (etag) => {
    //console.log("fetchCSVData starting ...");
    try {
      const options = {
        headers: {
          "If-None-Match": etag, // Use ETag to check if the file has changed
        },
      };
      //console.log("Fetching CSV data from URL:", FILE_URL);
      const response = await fetch(FILE_URL, options);

      if (response.status === 304) {
        //console.log("✅ File not modified, using cached data.");
        return; // File hasn't changed, no need to fetch
      }

      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const text = await response.text();
      //console.log("papaparse starts");
      Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          //console.log("✅ Parsed Data:", results.data);
          sessionStorage.setItem(CACHE_KEY, JSON.stringify({ timestamp: Date.now(), data: results.data }));
          setData(results.data);
          //console.log("papaparse data: " , results.data);
        },
        error: (error) => console.error("❌ Error parsing CSV:", error),
      });
    } catch (error) {
      console.error("❌ Error fetching or parsing CSV:", error);
    }
  };

  // ✅ Check Etags first & Decide Whether to Fetch Fresh Data
  useEffect(() => {
    let isMounted = true; // ✅ Ensure we only update state if component is mounted

    const checkCacheAndFetch = async () => {
      //console.log("🔄 Checking cache before fetching...");
      const cachedVersion = sessionStorage.getItem(CACHE_VERSION_KEY);
      const latestETag = await fetchMetadata(); // Get current etag 

      if (!isMounted) return; // ✅ Prevent updates after unmount

      //console.log("🔍 Comparing Versions | Cached:", cachedVersion, "| Latest:", latestETag);

      // ✅ If `etag` values match, use cached data
      if (cachedVersion === latestETag) {
        //console.log("✅ Etags match. Using cached data.");
        const cachedData = sessionStorage.getItem(CACHE_KEY);
        if (cachedData) {
          try {
            const parsedData = JSON.parse(cachedData);
            //console.log("✅ Parsed Cached Data:", parsedData);
            if (isMounted) setData(parsedData.data);
          } catch (error) {
            console.error("❌ Failed to parse cached data. Fetching fresh data.");
            sessionStorage.removeItem(CACHE_KEY);
            sessionStorage.removeItem(CACHE_VERSION_KEY);
            if (isMounted) await fetchCSVData(latestETag);
          }
        }
      } else {
        //console.log("⚠️ Cache outdated or missing. Fetching fresh data...");
        sessionStorage.setItem(CACHE_VERSION_KEY, latestETag);
        if (isMounted) await fetchCSVData(latestETag);
      }
    };

    checkCacheAndFetch();

    return () => {
      isMounted = false; // ✅ Cleanup to avoid state updates after unmount
    };
  }, []); // ✅ Run once when component mounts

  // 🔀 Shuffle Data Whenever `data` Updates
  useEffect(() => {
    if (data.length > 0) {
      setShuffledData([...data].sort(() => 0.5 - Math.random()));
    }
  }, [data]);

  // 📌 Filter Offers Based on Date
  const memberbenefitoffers = shuffledData.filter(({ startdate, enddate }) => {
    return currentDate >= new Date(startdate) && currentDate <= new Date(enddate);
  });

  const displayedOffers = memberbenefitoffers.slice(0, numberOfDisplayedOffers);

  return (
    <div className="MemberDealsSection">
      {displayedOffers.map((offer) => (
        <a
          className="MemberDealsImport"
          href={offer.bannerlinkfromrevive}
          target="_blank"
          rel="noopener noreferrer"
          key={offer.memberbenefitoffer}
        >
          <img src={offer.bannerasset} alt={offer.title} />
        </a>
      ))}
    </div>
  );
};

export default DealsImport;