import React, { useState, useEffect, useCallback } from 'react';
import Papa from 'papaparse';
import './BannerNatsPageMobileCookie.scss';

const BannerNatsPageMobileCookie = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);
  const [configData, setConfigData] = useState([]);
  const pagePath = window.location.pathname;
  const natsValue = new URLSearchParams(window.location.search).get('nats');

  const selectConfiguration = useCallback(
    (nats, url) => {
      const currentDate = new Date();
      return configData.find((config) => {
        const isActiveDate = currentDate >= new Date(config.StartTime) && currentDate <= new Date(config.EndTime);
        const isMatchingURL = url.includes(config.SiteUrl);
        const isMatchingNatsCode = nats ? nats.includes(config.NatsCode) : false;
        return (
          isActiveDate &&
          isMatchingURL &&
          (isMatchingNatsCode || localStorage.getItem(config.LocalStorageKey) === 'true')
        );
      });
    },
    [configData],
  );

  useEffect(() => {
    Papa.parse(
      'https://docs.google.com/spreadsheets/d/1NgxpY_TMug7l9ZAHqB1iwWar8lq3rJJJq859tGis_wc/gviz/tq?tqx=out:csv',
      {
        download: true,
        header: true,
        complete: (results) => {
          setConfigData(results.data);
        },
      },
    );
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 960);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const currentConfig = selectConfiguration(natsValue, pagePath);
    if (currentConfig) {
      const currentTime = new Date();
      const isActiveDate =
        currentTime >= new Date(currentConfig.StartTime) && currentTime <= new Date(currentConfig.EndTime);
      const storedValue = localStorage.getItem(currentConfig.LocalStorageKey);

      if (isActiveDate && (storedValue === 'true' || (natsValue && natsValue.includes(currentConfig.NatsCode)))) {
        setShowBanner(true);
        if (!storedValue && natsValue.includes(currentConfig.NatsCode)) {
          localStorage.setItem(currentConfig.LocalStorageKey, 'true');
        }
      } else {
        setShowBanner(false);
      }
    }
  }, [natsValue, pagePath, configData, selectConfiguration]);

  if (!showBanner) return null;

  const currentConfig = selectConfiguration(natsValue, pagePath);
  if (!currentConfig) return null;

  const imageSrc = isMobile ? currentConfig.MobileBannerUrl : currentConfig.DesktopBannerUrl;

  return (
    <a href={currentConfig.BannerLinkUrl}>
      <img
        src={imageSrc}
        alt={currentConfig?.LocalStorageKey || 'Default Alt Text'}
        className="bannerNatsPageMobileCookieImage"
      />
    </a>
  );
};

export default BannerNatsPageMobileCookie;
