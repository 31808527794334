import React from 'react';

import { isWeb } from '../../../services/util-service/util.service';
import SignIn from '../../../components/SignIn/SignIn.js';
import BannerSetViewFunc from '../../../components/BannerSetView/BannerSetViewFunc.jsx';
import { useHtmlContentContext } from '../../../context/HtmlContentContext.js';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent.js';
import './SignInPage.scss';

const SignInPage = () => {
  const { htmlContent } = useHtmlContentContext();

  return (
    <>
      {isWeb() && <BannerSetViewFunc bannerName="promo" />}
      <div className="SignInPage">
        <div className="Headline">{'Already a member? Please Sign In.'}</div>
        <SignIn />
      </div>

      <RenderHtmlContent htmlContent={htmlContent} dataLabel="support_service" />
      {isWeb() && <BannerSetViewFunc bannerName="tour" />}
    </>
  );
};

export default SignInPage;
