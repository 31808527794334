import React, { useEffect } from 'react';
import { Router, navigate } from '@reach/router';

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import ApplicationLogo from '../../components/ApplicationLogo/ApplicationLogo';
import AvsHome from './AvsHome/AvsHome';
import { AGE_WARNING_OVERLAY } from '../../components/AgeWarningOverlay/AgeWarningOverlay';
import RenderHtmlContent from '../../components/RenderHtmlContent/RenderHtmlContent';
import { LoadingText } from '../../components/LoadingMask/LoadingMask.js';
import AvsCancel from './AvsCancel/AvsCancel';
import AvsSuccess from './AvsSuccess/AvsSuccess';
import AvsError from './AvsError/AvsError';
import AvsNotFound from './AvsNotFound/AvsNotFound';

import { ROUTES } from '../../services/navigation/navigation.routes';
import { useHtmlContentContext } from '../../context/HtmlContentContext';
import { useWLconfigContext } from '../../context/WLConfigContext.js';

import './AvsPage.scss';

export const sessionExpires = () => {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  return date.toLocaleDateString();
};

export const RETURL = 'returl';

const AvsPage = () => {
  const { htmlContent, htmlContentLoading } = useHtmlContentContext();
  const { variables: {
    REACT_APP_SITE_NAME
  } = {} } = useWLconfigContext();
  const path = window.location.pathname;
  const urls = [ROUTES.ageVerificationFaq, ROUTES.ageVerificationTos, ROUTES.ageVerificationPrivacy];

  const getSearchParams = () => {
    try {
      // window.atob or decodeURIComponent might throw an exception
      const searchParams = window.location.search ? window.location.search?.slice(1) : null;

      if (searchParams) {
        const createURL = new URLSearchParams(searchParams);
        const getReturnURL = createURL.get(RETURL);
        const base64decode = window.atob(getReturnURL);
        const decodeURI = decodeURIComponent(base64decode);

        if (decodeURI && decodeURI.startsWith('http')) {
          localStorage.setItem(RETURL, decodeURI); // params are lost during YOTI process
        }
      }
    } catch (err) {
      console.error('Parameters are not encoded correctly!');
    }
  };

  getSearchParams();

  useEffect(() => {
    localStorage.setItem(AGE_WARNING_OVERLAY, 1);
  }, []);

  const linkToHome = path.includes(ROUTES.ageVerificationSuccess) ? ROUTES.root : `/${ROUTES.ageVerificationLanding}`;

  const renderLogo = () => {
    return (
      <div className="cursor-pointer" onClick={() => {
        if (path.includes(ROUTES.ageVerificationSuccess)) {
          window.location.href = linkToHome;
        } else {
          navigate(linkToHome);
        }
      }}>
        <ApplicationLogo notLink />
      </div>
    );
  };

  return (
    <ErrorBoundary siteName={REACT_APP_SITE_NAME}>
      {htmlContentLoading ? (
        <LoadingText />
      ) : (
        <div className="AvsPage">
          {renderLogo()}
          <Router>
            <AvsHome path={ROUTES.ageVerificationLanding} />
            <AvsCancel path={ROUTES.ageVerificationCancel} />
            <AvsSuccess path={ROUTES.ageVerificationSuccess} />
            <AvsError path={ROUTES.ageVerificationError} />
            {urls.map(url => {
              const pageUrl = url.slice(url.lastIndexOf('/') + 1); // privacy, tos, help
              return (
                <RenderHtmlContent key={url} path={url} htmlContent={htmlContent} dataLabel={pageUrl} />
              )
            })}
            <AvsNotFound linkToHome={linkToHome} default />
          </Router>
        </div>
      )}
    </ErrorBoundary>
  );
};

AvsPage.displayName = 'AvsPage';

export default AvsPage;
