import React from 'react';
import { Link } from '@reach/router';
import { urlSearchParamsPlaceholder } from '../../constants/navigation';
import { ROUTES } from '../../services/navigation/navigation.routes';

import './BaseSectionHeader.scss';

const BaseSectionHeader = ({
  primaryText = '',
  secondaryLink = '',
  secondaryText = '',
  className = '',
  children
}) => {
  const url =
    secondaryLink && secondaryLink?.includes(ROUTES.studios)
      ? `${secondaryLink}?${urlSearchParamsPlaceholder}`
      : secondaryLink;

  return (
    <div className="BaseSectionHeader">
      <div className="BaseSectionHeader-container">
        <div className={`BaseSectionHeader-primary ${className}`}>{primaryText?.toLowerCase()}</div>
        {children}
      </div>

      {secondaryLink && (
        <Link to={url} className="BaseSectionHeader-secondary">
          {secondaryText} <i className='fas fa-chevron-right' style={{ verticalAlign: 'bottom' }}></i>
        </Link>
      )}
    </div>
  );
};

BaseSectionHeader.displayName = 'BaseSectionHeader';

export default BaseSectionHeader;
