import React from 'react';
import SpecialOffersF4F from './f4fbanners';
import SpecialOffersF4F2 from './f4fbanners2';
import BaseSectionHeader from '../../components/BaseSectionHeader/BaseSectionHeader';
import BannerSetViewFunc from '../../components/BannerSetView/BannerSetViewFunc';

import './SpecialOffers.scss';

const SpecialOffersSwitcher = () => {
  const components = [<BannerSetViewFunc bannerName="exit" />, <SpecialOffersF4F />, <SpecialOffersF4F2 />];

  const randomize = (comps) => {
    return comps[Math.floor(Math.random() * comps.length)];
  };

  return (
    <>
      <div className='SpecOffersHeader'>
        <BaseSectionHeader primaryText='check out these great offers from our partners' />
      </div>
      <div className="SpecialOffersContent">{randomize(components)}</div>
    </>
  );
};

export default SpecialOffersSwitcher;
