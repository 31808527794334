import React from 'react';

const SpecialOffersF4F2 = () => {
  return (
    <>
      <iframe
        scrolling="no"
        title="FlirtForFreeVideo1"
        src="https://camspacelive.com/widgets/live.php?cta=gtr&style=expandable-theme001-0001&mp_code=b2ew&service=guys&language=en&use_promo=0&continuous_play=1&model_id=&bgcolor=FFFFFF&txtcolor=FFFFFF&linkcolor=FFFFFF&num_models=24&sitekey=whitelabel&whitelabel_domain=nakedswordlive.com&target=_blank&btncolor=333333&btntxtcolor=CCCCCC&accentcolor=515151"
        width="850"
        height="980"
        style={{
          display: 'block',
          width: '67%',
          margin: '0 auto'
        }}
      ></iframe>
      <p style={{ width: '100%', textAlign: 'center' }}>NakedSwordLive.com the hottest guys online.</p>
    </>
  );
};

export default SpecialOffersF4F2;
